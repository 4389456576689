@import 'sass-mq';

$pad: 16px;

$base-orange-color: #ef7d45;
$base-darkgreen-color: #004438;
$color: $base-orange-color;

html {
  height: 100%;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

body, #root {
  margin: 0;
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.main-content {
  color: white;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: $pad*3;
  padding: $pad;
  max-width: 1120px;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  //background: rgba(15,29,67,.7);
  z-index: 1;
}

.timer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: $pad*2;

  @include mq($from: mobile, $until: tablet) {
    grid-column-gap: $pad;
  }


  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 42px;
    background-color: $color;
    color: white;
    padding: $pad 0;
    font-weight: 400;
    box-shadow: 0 5px 50px -10px #fff;


  @include mq($from: mobile, $until: tablet) {
    font-size: 32px;
}

    div {
      padding-top: $pad/2;
      font-size: 14px;
      font-weight: 400;
      @include mq($from: mobile, $until: tablet) {
        font-size: 12px;
    }
    }
  }
}

.title {
  text-align: center;
  //background: rgba(15, 29, 67, 0.85);
  //padding: 32px;
  border-radius: 8px;


  &__main {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 48px;

    @media only screen and (max-width: 800px) {
      font-size: 24px;
    }

    span{
      font-weight: 700;
      //color: rgb(159, 0, 79);;
    }
  }

  &__sub {
    span{
      //color: rgb(159, 0, 79);;
      font-weight: 700;
    }
  }
}

.subscribe {
  max-width: 640px;
  margin: 0 auto;

  &__title {
    text-align: center;
    margin-bottom: $pad;
  }

 form {
    input {
      border: none;

      padding: 8px 16px;
      font-weight: 400;
      color: #71787c;
      background: #fff;
      border-radius: 24px;
      -webkit-tap-highlight-color: rgba(73,73,73,0);
      line-height: normal;
      font-size: 18px;
      height: 32px;


      &:focus {
        outline: none;
      }
    }

    button {
      background-color: $color;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 16px;
      line-height: 1.5;
      height: 48px;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 24px;

      &:focus {
        outline: none;
      }
    }
    
    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 16px;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      row-gap: 16px;
    }

    p {
      color: white!important;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: $color;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    font-size: 14px;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-field-error {
  margin-top: $pad/2;
}